.flex-format {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.format {
    width: 100%;
    height: 100%;
    flex: 1;
}

.center-children {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}