.centerChildren {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.aboutMeParagraph {
    width: 10,
}

.contactInformation {
    margin-top: 128;
    padding: 8;
    display: flex;
    flex-direction: row;
    border: 1;
    border-color: black;
}
